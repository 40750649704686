<template>
  <div>
    <b-container v-show="!load_form_data">
      <b-row>
        <b-col md="auto">
          {{init_err_msg || '正在初始化页面，请稍后'}}
        </b-col>
      </b-row>
    </b-container>
    <b-container v-show="load_form_data && !init">
      <b-row v-if="!doing">
        <b-col md="auto">
          <b-form @submit="onSubmit" @reset="onReset">
            <b-form-checkbox
              v-model="form.reget"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >
              重新抓取文本
            </b-form-checkbox>

            <b-form-group label="源语言:" label-for="source_language">
              <b-form-select
                id="source_language"
                v-model="form.source_language"
                :options="languages"
                class="form-control"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group label="目标语言:" label-for="target_language">
              <b-form-select
                id="target_language"
                v-model="form.target_language"
                :options="languages"
                class="form-control"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group  
              v-for="(category) in categorys" :key="`category-${category.value}`"
              :label="category.text" :label-for="`category-${category.value}`">
              <b-form-select
                :id="`category-${category.value}`"
                class="form-control"
                v-model="form[category.value]"
                :options="category.tags"
              ></b-form-select>
            </b-form-group>
            <b-button :disabled="doing" type="submit" variant="primary">提取文本</b-button>
            <b-button :disabled="doing" type="reset" variant="danger">重置</b-button>
          </b-form>
          <br><br>
        </b-col>
      </b-row>
      <b-row v-if="doing">
        <b-col md="auto">
          <div class="task-process"><b-badge :variant="step_status[step1]">{{step_status_name[step1]}}</b-badge> 1，初始化word文档信息</div>
          <div class="task-process"><b-badge :variant="step_status[step2]">{{step_status_name[step2]}}</b-badge> 2，开始抓取文本，进度
            <b-progress :max="step2_total" v-if="step2 > 1" style="width:200px;position:relative;">
              <b-progress-bar :value="step2_count">
                <div style="position:absolute;width:100%;height:100%;text-align:center;">
                  {{ step2_count }} / {{ step2_total }}
                </div>
              </b-progress-bar>
            </b-progress>
          </div>
          <div class="task-process"><b-badge :variant="step_status[step3]">{{step_status_name[step3]}}</b-badge> 3，开始翻译，进度
            <b-progress :max="step3_total" v-if="step3 > 1" style="width:200px;">
              <b-progress-bar :value="step3_count">
                {{ step3_count }} / {{ step3_total }}
              </b-progress-bar>
            </b-progress>
          </div>
          <div class="task-process"><b-badge :variant="step_status[step4]">{{step_status_name[step4]}}</b-badge> 4，格式化数据</div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-show="load_form_data && init && show_list">
      <b-row>
        <b-col md="auto">
          <b-button variant="primary" @click="changeSearch">修改条件</b-button>
          &nbsp;
          <b-button variant="primary" @click="replaceAll">替换已确认的文本</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-table
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            small
          >
            <template #cell(status)="data">
              <b-badge v-if="data.item.status == 1" variant="success">已确认</b-badge>
              <b-badge v-if="data.item.status != 1" variant="secondary">未确认</b-badge>
            </template>
            <template #cell(src)="data">
              {{data.item.src}}
            </template>
            <template #cell(tool)="data">
              <b-button variant="primary" small @click="edit(data.item.id)" style="white-space: nowrap;">编辑</b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div style="display: flex;align-items: center;">
          <b-form-input type="number" v-model="currentPage"></b-form-input><div style="white-space: nowrap;margin-left:5px;"> / {{total_page}}</div>
        </div>
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-text="首页"
            prev-text="上一页"
            next-text="下一页"
            last-text="最后一页"
          ></b-pagination>
      </div>
    </b-container>
    
    <b-container v-show="load_form_data && init && !show_list">
      <b-row>
        <b-col md="12">
          <div style="display:flex;justify-content: space-between;">
            <b-button variant="secondary" @click="gotolist">返回列表</b-button>
            <div>
              <b-button variant="secondary" :disabled="item_index <= 0" @click="edit(item_index - 1)">上一个</b-button>
              &nbsp;
              {{item_index + 1}} / {{items.length}}
              &nbsp;
              <b-button variant="secondary" :disabled="item_index >= items.length - 1" @click="edit(item_index + 1)">下一个</b-button>
            </div>
            <div>
              <b-button variant="primary" @click="save">保存</b-button>
              &nbsp;
              <b-button variant="primary" @click="savereplace">保存并替换</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <br>
          <b-form-group label="原文:" label-for="txt_src">
            <b-form-textarea
            id="txt_src"
            v-model="item_data.src"
            rows="3"
          ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-button variant="secondary" @click="gotoword">定位</b-button>
          &nbsp;
          <b-button variant="secondary" @click="replaceword">替换</b-button>
        </b-col>
        <b-col md="12">
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>文本</b-th>
                <b-th>翻译</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(node,node_index) in item_data.html_nodes" :key="`node-${node_index}`" :style="node.text?'':'display:none;'">
                <b-td>{{node.text}}</b-td>
                <b-form-input :disabled="!node.text" v-model="node.translate" placeholder="请输入翻译的内容"></b-form-input>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col md="12" v-if="item_data.translate">
          <h3>全文翻译：</h3>
          {{item_data.translate}}
        </b-col>
        <b-col md="12">
          <h3>预览效果：</h3>
          <div v-html="buildTreeNode(item_data.tree_nodes)"></div>
        </b-col>
        <b-col md="12" v-if="item_data.translate_list && item_data.translate_list.length">
          <h3>部分翻译：</h3>
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>原文</b-th>
                <b-th>翻译</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(translate,translate_index) in item_data.translate_list" :key="`translate-${translate_index}`">
                <b-td>{{translate.source_content}}</b-td>
                <b-td>{{translate.target_content}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import word_data from '../js/word.json';
import {GetTagCategoryList,GetTagListByCategory,GetCodeValueList} from '../api/common/index';
import translate from '../js/translate.js';

const is_need_translate = (value)=>{
  if(!value)return false;
  if(!isNaN(value))return false;
  return true;
};
const reg_body = /<body[^>]*?>[\s\S]+?<\/body>/g;

const build_html_nodes = ($roots)=>{
  let $ = window.$;
  let result_node = [];
  let result = []

  const _repeat = (num)=>{
    let a = new Array(num);
    a.fill(' ');
    return a.join('')
  };

  const _build = ($node)=>{
    let nodeName = $node[0].nodeName
    let outer = $node[0].outerHTML;
    if(nodeName == "#text"){
      let text = $node[0].wholeText.replaceAll('\n',' ').replaceAll('&nbsp;',' '); 
      if(text.trim()){
        let r_node = {
          begin:'',
          end:'',
          text:text.trim(),
          translate:'',
          text_before:_repeat(text.length - text.trimStart().length),
          text_after:_repeat(text.length - text.trimEnd().length),
          children:[]
        }
        result.push(r_node);
        return r_node;
      }else{
        return {
          begin:'',
          end:'',
          text:text.trim(),
          translate:'',
          text_before:_repeat(text.length - text.trimStart().length),
          text_after:'',
          children:[]
        }
      }
    }else if(outer.endsWith("/>")){
      return {
        begin:outer,
        end:'',
        text:'',
        translate:'',
        text_before:'',
        text_after:'',
        children:[]
      }
    }else if($node[0].childNodes.length == 0){
      outer = outer.replace($node.html(),'');
      let text = $node.text().replaceAll('\n',' ').replaceAll('&nbsp;',' ');
      let r_node = {
        begin:outer.substring(0,outer.length - nodeName.length - 3),
        end:outer.substring(outer.length - nodeName.length - 3),
        text:text.trim(),
        translate:'',
        text_before:_repeat(text.length - text.trimStart().length),
        text_after:_repeat(text.length - text.trimEnd().length),
        children:[]
      }
      result.push(r_node);
      return r_node;
    }else{
      outer = outer.replace($node.html(),'');
      let node_data = {
        begin:outer.substring(0,outer.length - nodeName.length - 3),
        end:outer.substring(outer.length - nodeName.length - 3),
        text:'',
        translate:'',
        text_before:'',
        text_after:'',
        children:[]
      };
      for (let index = 0; index < $node[0].childNodes.length; index++) {
        const cnode = $node[0].childNodes[index];
        node_data.children.push(_build($(cnode)));
      }
      return node_data;
    }
  }
  for (let index = 0; index < $roots.length; index++) {
    const $root = $($roots[index]);
    result_node.push(_build($root));
  }
  return {result,result_node};
};

export default {
  name: 'office-word',
  created() {
    this.init_form_data().then(()=>{
      this.load_form_data = true;
    }).catch(()=>{
      this.init_err_msg = '加载失败，请关闭插件，重新打开插件'
    });
    window.Office.onReady((info) => {
      if (info.host === window.Office.HostType.Word) {
        console.log('ready');
      }
    });
  },
  data() {
    return {
      load_form_data:false,
      init_err_msg:'',
      form:{
        reget:1,
        source_language:'',
        target_language:'',
      },
      languages:[],
      categorys:[],

      debug:process.env.VUE_APP_DEBUG == '1',
      doing:false,
      init:false,
      step_status:{
        1:'secondary',
        2:'info',
        3:'success',
        4:'danger',
      },
      step_status_name:{
        1:'待执行',
        2:'执行中',
        3:'执行成功',
        4:'执行失败',
      },
      step1:1,
      step2:1,
      step2_count:30,
      step2_total:110,
      step3:1,
      step3_count:30,
      step3_total:110,
      step4:1,

      show_list:true,
      item_index:0,
      perPage: 20,
      currentPage: 1,
      fields:[{
        key: 'status',
        label: '状态',
        thStyle:'width:100px;'
      },{
        key: 'src',
        label: '原文',
        thStyle:'min-width:100px;'
      },{
        key: 'translate',
        label: '翻译',
        thStyle:'min-width:100px;'
      },{
        key: 'tool',
        label: ' ',
        thStyle:'width:100px;'
      }],
      items: [],
      item_data:{
        src:'',
        html:'',
        translate:'',
        control:null,
        translate_list:[],
        html_nodes:[],
        tree_nodes:[],
      },
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
    total_page(){
      return Math.ceil(this.items.length / this.perPage);
    },
  },
  methods:{
    alert(msg){
      this.$bvToast.toast(msg, {
        title: '系统提示',
        autoHideDelay: 5000
      })
    },
    async init_form_data(){
      let languages = await GetCodeValueList({code_schema:'language'});
      this.languages = [{
          text:'请选择...',
          value:'',
        },...languages.platform_code_value.map(i=>{
        return {
          text:i.name,
          value:i.value,
        }
      })];
      let categorys = await GetTagCategoryList();
      this.categorys = categorys.get_tag_category_list.map(i=>{
        return {
          text:i.name,
          value:i.category_guid,
        }
      });
      for (let index = 0; index < this.categorys.length; index++) {
        const category = this.categorys[index];
        this.form[category.value] = ''
        let tags = await GetTagListByCategory({category_guid:category.value});
        category.tags = [ {
            text:'全部',
            value:'',
          },...tags.get_tag_list_by_category.map(i=>{
          return {
            text:i.tag_schema,
            value:i.tag_guid,
          }
        })];
      }
    },

    onSubmit(e){
      e.returnValue = false;
      this.getWordData();
    },
    onReset(){
      this.form.target_language = '';
      this.form.source_language = '';
      for (let index = 0; index < this.categorys.length; index++) {
        const category = this.categorys[index];
        this.form[category.value] = ''
      }
    },
    gotoword(){
      window.Word.run(async (context) => {
        context.document.body.paragraphs.load();
        await context.sync();
        let control = context.document.body.paragraphs.items.find(i=>i._Id == this.item_data.control._Id)
        if(control){
          control.select();
          await context.sync();
        }else{
          this.alert('没有找到对应的段落，如果文档已经修改，请重新加载')
        }
        
      });
    },
    replaceword(){
      window.Word.run(async (context) => {
        context.document.body.paragraphs.load();
        await context.sync();
         let control = context.document.body.paragraphs.items.find(i=>i._Id == this.item_data.control._Id)
         if(control){
          control.insertHtml(this.buildTreeNode(this.item_data.tree_nodes), window.Word.InsertLocation.replace);
          await context.sync();
        }else{
          this.alert('没有找到对应的段落，如果文档已经修改，请重新加载')
        }
      });
    },
    replaceAll(){
      window.Word.run(async (context) => {
        context.document.body.paragraphs.load();
        await context.sync();
        let items = this.items.filter(i=>i.status == 1);
        let has_error = false;
        for (let index = 0; index < items.length; index++) {
          const item = items[index];
          let control = context.document.body.paragraphs.items.find(i=>i._Id == item.control._Id);
          if(control){
            control.insertHtml(this.buildTreeNode(item.tree_nodes), window.Word.InsertLocation.replace);
          }else{
            has_error = true;
          }
        }
        await context.sync();
        if(has_error){
          this.alert('没有找到对应的段落，如果文档已经修改，请重新加载')
        }
      });
    },
    gotolist(){
      this.show_list = true;
    },
    save(b){
      this.items[this.item_index].status = this.items[this.item_index].translate_list.filter(i=>!i.target_content).length == 0?1:0;
      if(b === true){
        this.edit(this.item_index + 1);
      }
    },
    savereplace(){
      this.items[this.item_index].status = this.items[this.item_index].translate_list.filter(i=>!i.target_content).length == 0?1:0;
      this.replaceword();
    },
    edit(index){
      this.show_list = false;
      this.item_index = index;
      this.item_data.src= this.items[index].src;
      this.item_data.html= this.items[index].html || '';
      this.item_data.html_nodes = this.items[index].html_nodes;
      this.item_data.tree_nodes = this.items[index].tree_nodes;
      this.item_data.translate_list = this.items[index].translate_list;
      this.item_data.control = this.items[index].control;
    },
    buildTreeNode(tree_nodes){
      const _build = (node)=>{
        if(node.children.length == 0){
          return node.begin + node.text_before + (node.translate || node.text) + node.text_after + node.end;
        }else{
          return node.begin + node.children.map(i=>_build(i)).join('') + node.end;
        }
      };
      return tree_nodes.map(i=>_build(i)).join('');
    },
    changeSearch(){
      this.form.reget = "0";
      this.init = false;
    },
    getWordData(){
      console.log('getWordData')
      this.doing = true;
      this.step1 = 1;
      this.step2 = 1;
      this.step3 = 1;
      this.step4 = 1;
      this.step2_count = 0;
      this.step2_total = 0;
      this.step3_count = 0;
      this.step3_total = 0;
      let Word = window.Word;
      if(this.debug){
        Word = {
          run(fn){
            let context = {
              sync(){
                return new Promise((resolve)=>{
                  resolve();
                });
              },
            };
            fn(context).then(()=>{

            }).catch(()=>{

            })
          },
        };
      }
      let $ = window.$;

      /*
      let controller = new AbortController();
      controller.abort();
      */
      //1，初始化word文档信息
      this.step1 = 2;
      Word.run(async (context) => {
        let pagedata = [];

        if(this.debug){
          this.step1 = 3;
          this.step2 = 3;
          pagedata = word_data.filter(i=>is_need_translate(i.src)).map((i,j)=>{
            i.id = j;
            i.translate = '';
            i.translate_list = [];
            i.status = 0

            let matches_body = i.ori_html.match(reg_body);
            if(matches_body){
              matches_body = matches_body[0];
              matches_body = $(`<div>${matches_body}</div>`).find(".WordSection1").html();
              matches_body = matches_body.replaceAll(`<p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>`,"");
              i.html = matches_body;
              i.html_nodes = [];
              i.$root = $(`<div>${matches_body}</div>`);
              let {result,result_node}= build_html_nodes(i.$root.children());
              i.html_nodes = result;
              i.tree_nodes = result_node;
            }
            return i;
          });
        }else{
          if(this.form.reget == "1"){
            //如果需要重新抓取文本
            await context.sync();
            const paragraphs = context.document.body.paragraphs;
            this.step1 = 3;

            //2，开始抓取文本
            paragraphs.load();
            await context.sync();
            this.step2 = 2;
            this.step2_total = paragraphs.items.length;
            this.step3_total = paragraphs.items.length;

            for(let i = 0;i<paragraphs.items.length;i++){
              const p = paragraphs.items[i];
              let html_control = p.getHtml();
              await context.sync();
              let matches_body = html_control.value.match(reg_body);
              if(matches_body){
                matches_body = matches_body[0];
                matches_body = `<div${matches_body.substring(5,matches_body.length - 5)}div>`
                matches_body = $(matches_body).find(".WordSection1").html();
                matches_body = matches_body.replaceAll(`<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>`,"");
              }else{
                matches_body = '';
              }

              let $root = $(`<div>${matches_body}</div>`);
              let {result,result_node}= build_html_nodes($root.children());

              pagedata.push({
                id:i,
                status:0,
                src:paragraphs.items[i].text,
                translate:'',
                translate_list:[],
                control:paragraphs.items[i],
                ori_html:html_control.value,
                html_control:html_control,
                html:matches_body,
                html_nodes:result,
                tree_nodes:result_node,
                $root:$root,
              });
              this.step2_count = pagedata.length;
            }
            this.step2 = 3;
          }else{
            pagedata = this.items;
            for (let index = 0; index < pagedata.length; index++) {
              pagedata[index].translate = '';
              pagedata[index].status = 0;
              pagedata[index].translate_list = [];
              pagedata[index].html_nodes.forEach(i=>{
                i.translate = '';
              });
            }
          }
        }

        //开始翻译
        this.step3 = 2;
        let tags = []
        for (let index = 0; index < this.categorys.length; index++) {
          const category = this.categorys[index];
          if(this.form[category.value]){
            tags.push(this.form[category.value]);
          }
        }
        let words = await translate({
          source_language:this.form.source_language,
          target_language:this.form.target_language,
          tags:tags
        });
        words = words.words
        let regalpha = /^\w+$/g
        for (let index = 0; index < pagedata.length; index++) {
          const element = pagedata[index];
          let text = element.src;
          
          let translate_list = words.filter(w=>{
            return text.toLowerCase().includes(w.source_content.toLowerCase());
          });
          translate_list = translate_list.filter(w=>{
            if(regalpha.test(w.source_content)){
              //不是中文
              return new RegExp(`\\b${w.source_content}\\b`,"gi").test(text);
            }else{
              //是中文
              return true;
            }
          });
          let translate_word = translate_list.find(w=>w.source_content==text) || '';
          if(translate_word){
            element.translate = translate_word.target_content;
          }
          element.translate_list = translate_list;

          let html_nodes = element.html_nodes;
          html_nodes.forEach(i=>{
            let node_text = i.text;
            let node_translate_list = words.filter(w=>{
              return node_text.toLowerCase().includes(w.source_content.toLowerCase());
            });
            node_translate_list = node_translate_list.filter(w=>{
              if(regalpha.test(w.source_content)){
                //不是中文
                return new RegExp(`\\b${w.source_content}\\b`,"gi").test(node_text);
              }else{
                //是中文
                return true;
              }
            });
            let node_translate_word = node_translate_list.find(w=>w.source_content==text) || '';
            if(node_translate_word){
              i.translate = node_translate_word.target_content;
            }
          });

          this.step3_count += 1
        }
        this.step3 = 3;

        this.items = pagedata;
        this.doing = false;
        this.init = true;
      });
    }
  }
}
</script>

<style scoped>
  .task-process{
    display: flex;
    align-items: center;
  }
</style>
