<template>
  <div id="app">
    <b-container>
      <b-row>
        <b-col md="auto">
          <br><h2>版本：1.0.0</h2><br>
        </b-col>
      </b-row>
    </b-container>
    <word></word>
  </div>
</template>

<script>
//_host_Info=Word$Win32$16.01$zh-CN$$$$0
import word from './pages/word.vue';
export default {
  name: 'App',
  components:{
    word
  }
}
</script>

<style>
</style>
